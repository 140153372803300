import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const Title = () => <span>
    First line <br /> Second line
  </span>;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  Title,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageDescription mdxType="PageDescription">
      <p>{`MDX allows for certain things beyond what markdown is capable of. Content here
will discuss using those features to augment or modify the default content
layout.`}</p>
    </PageDescription>
    <h2>{`Frontmatter`}</h2>
    <p>{`You can declare frontmatter in your `}<inlineCode parentName="p">{`.mdx`}</inlineCode>{` files to provide specific metadata for the theme to use.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`title`}</inlineCode>{`: Main page title: search results and SEO`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`description`}</inlineCode>{`: SEO and search results`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`keywords`}</inlineCode>{`: just SEO (optional)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`hiddenFromSearch`}</inlineCode>{`: if true, page will be excluded from search`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`---
title: Markdown
description: Usage instructions for the Markdown component
keywords: 'ibm,carbon,gatsby,mdx,markdown'
hiddenFromSearch: true
---
`}</code></pre>
    <h2>{`Smart quotes`}</h2>
    <p>{`The theme has a remark for processing straight quotes, into ‘smart’ quotes (”). However, this plugin isn’t able to process text used in custom MDX components.
When using quotes in custom components, content authors should manually use `}<a parentName="p" {...{
        "href": "https://www.figma.com/design-systems/"
      }}>{`“smart quotes”`}</a>{` to adhere to the IBM Design Language content guidelines.`}</p>
    <h2>{`Custom title`}</h2>
    <p>{`You can export a `}<inlineCode parentName="p">{`Title`}</inlineCode>{` component in order to render a unique title for a single page. This is particularly useful for including line breaks at a specific location.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` You still need to provide a regular string title to the frontmatter for search, navigation, and the HTML header title to work.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`---
title: MDX
description: custom title page
---

export const Title = () => (
  <span>
    First line <br /> Second line
  </span>
);
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      